import React from "react"
import { Link } from "gatsby"
import HeroImage from "../../../contents/Home/HeroImage"
import SvgDevices from "../../../contents/Home/SvgDevices"
import SvgDeadPixelRepair from "../../../contents/Home/SvgDeadPixelRepair"
import SvgStuckPixelRepair from "../../../contents/Home/SvgStuckPixelRepair"
import SEO from "../../../components/Seo"
const HomePage = () => {
  return (
    <>
      <SEO
        title="Prueba de Píxeles"
        lang="es"
        description="Prueba para píxeles muertos, píxeles atascados, reparar píxeles muertos y píxeles atascados en sus monitores, pantallas, tabletas, computadoras y televisores."
        keywords="Prueba de píxeles muertos, prueba de píxeles atascados, reparación de píxeles atascados, reparación de píxeles muertos, prueba de píxeles atascados, lo que es píxel muerto, lo que se atasca Pixel, lo que está atascado Pixel, la diferencia de píxeles de píxeles muertos, prueba de píxeles muertos., Pixel muerto, Pixel Test, Pixel atascado, Pixel Repair Online"
      />
      <h1 className="pb-0 mb-0 text-center">
        ¡Compruebe si hay píxeles{" "}
        <span className="text-indigo-600">muertos</span> o{" "}
        <span className="text-red-500">atascados!</span>
      </h1>

      <HeroImage className="max-w-full px-3 md:px-10" />

      <p className="lead">
        Puede probar los píxeles muertos o atascados en sus teléfonos móviles,
        tabletas, televisores y computadoras a través del navegador y utilizar
        la herramienta gratuita de reparación de píxeles sin ninguna
        instalación.
      </p>
      <h2>Prueba de pixel muerto o atascado</h2>
      <p>
        Ya sea tableta, teléfono o monitor ... Cuando compra cualquier
        dispositivo con pantalla, debe realizar la prueba de píxeles muertos y
        la prueba de píxeles atascados después de la instalación.
      </p>
      <p>
        Cuanto antes note un defecto potencial en el producto que compró, más
        fácil será procesar la devolución y el cambio. Además, este proceso no
        lleva mucho tiempo, puede probar fácilmente los píxeles muertos y los
        píxeles atascados en unos minutos.
      </p>
      <SvgDevices className="max-w-full px-3 md:px-10" />

      <p>
        Puede comenzar la prueba yendo a la página correspondiente haciendo clic
        en el botón{" "}
        <code className="capitalize">de prueba de píxel muerto</code> a
        continuación. En la página que se abre, hay información detallada sobre
        la prueba de píxel muerto y píxel atascado, así como la herramienta de
        prueba.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/prueba-de-pixeles-muertos"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            de prueba de píxel muerto
          </span>
        </Link>
      </div>
      <h2>
        ¿Cómo reparar píxeles muertos, solucionar problemas de píxeles muertos?
      </h2>
      <p>
        Un píxel muerto significa que un píxel de la pantalla ha llegado al
        final de su vida útil. Dado que no requiere energía, generalmente es de
        color negro. No existe una solución de software para solucionar el
        problema de píxeles muertos, por lo general se requiere el reemplazo del
        panel y la pantalla para esto. Empujar suavemente hacia abajo puede
        funcionar si hay píxeles muertos en las pantallas LCD causados por la
        emisión de cristales líquidos.
      </p>
      <SvgDeadPixelRepair className="max-w-full px-3 md:px-10" />

      <p>
        En la página que abrirá haciendo clic en el botón{" "}
        <code className="capitalize">Reparar píxeles muertos</code> a
        continuación, se explica paso a paso cómo eliminar sus píxeles muertos
        presionando hacia abajo suavemente.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correccion-de-pixeles-muertos"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">Reparar píxeles muertos</span>
        </Link>
      </div>
      <h2>
        ¿Cómo reparar el píxel pegado, arreglar el problema de pixel pegado?
      </h2>
      <p>
        Los colores que no cambian en su pantalla,{" "}
        <strong className="italic font-bold">
          especialmente si su color no es blanco o negro
        </strong>
        , no siempre significan que son píxeles muertos. Los píxeles que
        permanecen en un color durante mucho tiempo pueden quedarse atascados en
        colores como <span className="text-red-600">rojo</span>,{" "}
        <span className="text-green-400">verde</span>,{" "}
        <span className="text-blue-600">azul</span>.
      </p>
      <SvgStuckPixelRepair className="max-w-full px-3 md:px-10" />
      <p>
        Hay una herramienta de reparación gratuita para píxeles atascados en la
        página que se abre al hacer clic en el botón{" "}
        <code className="capitalize">Reparar píxeles atascados</code> a
        continuación. Además de informar sobre el uso de la herramienta de
        reparación de píxeles atascados, también puede encontrar información
        sobre qué es el píxel atascado y la diferencia entre el píxel atascado y
        el píxel muerto en la página de reparación de píxeles atascados.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/correccion-de-pixeles-atascados"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white capitalize">
            Reparar píxeles atascados
          </span>
        </Link>
      </div>
    </>
  )
}
export default HomePage
